import { memo } from 'react'
import { theme } from '../../../styles/theme'

export interface IHtmlBlock {
  code: string
}

function HtmlBlockComponent({ code }: IHtmlBlock): JSX.Element {
  if (!code) return null
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: code }} className="HtmlBlock" />

      <style jsx>{`
        :global(.Main__Body) .HtmlBlock {
          padding: 0 ${theme.block.marginLR}px;
          margin-bottom: 50px;
        }
      `}</style>
    </>
  )
}

export const HtmlBlock = memo(HtmlBlockComponent)
